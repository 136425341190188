import {
  AvatarBox,
  DropDownButton,
  InfoIcon,
  PersonIcon,
  PersonOffIcon,
  Text,
} from '@guider-global/ui';
import Error from '@mui/icons-material/PersonAdd';
import { MenuItem as MuiMenuItem } from '@mui/material';
import { MembershipProfileStatus } from 'views';
import { getMembershipProfileState } from './functions';

export type MembershipStatusStackProps = {
  role: 'guide' | 'trainee';
  isPublished: boolean;
  isBlocked: boolean;
  requiresApproval: boolean;
  onSelectNewStatus: (
    profileStatus: MembershipProfileStatus,
  ) => void | Promise<void>;
};

export function MembershipStatusStack({
  role,
  isPublished,
  isBlocked,
  requiresApproval,
  onSelectNewStatus,
}: MembershipStatusStackProps) {
  const membershipProfileState = getMembershipProfileState({
    role,
    isPublished,
    isBlocked,
    requiresApproval,
  });

  const profileStatusLabel = (() => {
    if (!membershipProfileState.isGuide) return 'N/A';
    const { profileStatus } = membershipProfileState;
    if (profileStatus === 'listed') return 'Listed';
    if (profileStatus === 'unlisted') return 'Unlisted';
    if (profileStatus === 'requires-approval') return 'Approval';
    if (profileStatus === 'blocked') return 'Blocked';
    return 'N/A';
  })();

  if (!membershipProfileState.isGuide) {
    return <></>;
  }
  return (
    <>
      <Text text="Profile Status" variant="caption" />
      <DropDownButton
        variant="text"
        color="info"
        label={profileStatusLabel}
        header="Profile status"
      >
        <MuiMenuItem onClick={() => onSelectNewStatus('listed')}>
          <AvatarBox
            icon={<PersonIcon size="small" />}
            size="small"
            heading="Listed"
            subtitles={['Profile is listed and can be matched with.']}
          />
        </MuiMenuItem>
        <MuiMenuItem onClick={() => onSelectNewStatus('unlisted')}>
          <AvatarBox
            icon={<PersonOffIcon size="small" />}
            size="small"
            heading="Unlisted"
            subtitles={['Profile can not be matched with.']}
          />
        </MuiMenuItem>
        <MuiMenuItem onClick={() => onSelectNewStatus('requires-approval')}>
          <AvatarBox
            icon={<InfoIcon size="small" />}
            size="small"
            heading="Approval"
            subtitles={['Awaiting admin decision.']}
          />
        </MuiMenuItem>
        <MuiMenuItem onClick={() => onSelectNewStatus('blocked')}>
          <AvatarBox
            icon={<Error />}
            size="small"
            heading="Blocked"
            subtitles={['Profile is blocked from creating profile.']}
          />
        </MuiMenuItem>
      </DropDownButton>
    </>
  );
}
