import { restReduxGenerator } from '@guider-global/redux-axios-hooks';
import {
  IChart,
  IChartsProgramGuideProfile,
} from '@guider-global/shared-types';

export const adminChartsProgramGuideProfilesSlice = restReduxGenerator<
  'adminChartsProgramGuideProfiles',
  IChart<IChartsProgramGuideProfile>
>('adminChartsProgramGuideProfiles');
