import { Outlet } from 'react-router-dom';
import { DatadogContext, useDatadog } from '@guider-global/datadog';
import { useUsers } from 'hooks';
import { ErrorView } from '@guider-global/ui';
import { useMemo } from 'react';

export type DatadogWrapperProps = { children?: JSX.Element };

export function DatadogWrapper({ children }: DatadogWrapperProps) {
  const {
    REACT_APP_DATADOG_APPLICATION_ID,
    REACT_APP_DATADOG_CLIENT_TOKEN,
    REACT_APP_DATADOG_SITE,
    REACT_APP_DATADOG_SERVICE,
    REACT_APP_DATADOG_RUM_SESSION_SAMPLE_RATE,
    REACT_APP_DATADOG_RUM_SESSION_REPLAY_RATE,
    REACT_APP_DATADOG_LOGGING_REPLAY_RATE,
    REACT_APP_VERSION,
    REACT_APP_DATADOG_RUM_SESSION_REPLAY_ENABLED,
    REACT_APP_DATADOG_LOGGING_ENABLED,
  } = process.env;

  const isDatadogEnabled = REACT_APP_DATADOG_LOGGING_ENABLED;

  const undefinedEnvVariables = [
    {
      key: 'REACT_APP_DATADOG_APPLICATION_ID',
      value: REACT_APP_DATADOG_APPLICATION_ID,
    },
    {
      key: 'REACT_APP_DATADOG_CLIENT_TOKEN',
      value: REACT_APP_DATADOG_CLIENT_TOKEN,
    },
    { key: 'REACT_APP_DATADOG_SITE', value: REACT_APP_DATADOG_SITE },
    { key: 'REACT_APP_DATADOG_SERVICE', value: REACT_APP_DATADOG_SERVICE },
    {
      key: 'REACT_APP_DATADOG_RUM_SESSION_SAMPLE_RATE',
      value: REACT_APP_DATADOG_RUM_SESSION_SAMPLE_RATE,
    },
    {
      key: 'REACT_APP_DATADOG_RUM_SESSION_REPLAY_RATE',
      value: REACT_APP_DATADOG_RUM_SESSION_REPLAY_RATE,
    },
    {
      key: 'REACT_APP_DATADOG_LOGGING_REPLAY_RATE',
      value: REACT_APP_DATADOG_LOGGING_REPLAY_RATE,
    },
    { key: 'REACT_APP_VERSION', value: REACT_APP_VERSION },
    {
      key: 'REACT_APP_DATADOG_RUM_SESSION_REPLAY_ENABLED',
      value: REACT_APP_DATADOG_RUM_SESSION_REPLAY_ENABLED,
    },
  ]
    .filter(
      (envVariable) =>
        envVariable.value === undefined || envVariable.value === null,
    )
    .map((envVariableObject) => envVariableObject.key);

  const { users } = useUsers({
    getSilently: true,
  });
  const user = users()[0];

  const { sessionId } = useDatadog({ user });

  const sessionIdMemo = useMemo(() => sessionId, [sessionId]);

  if (isDatadogEnabled && undefinedEnvVariables.length > 0) {
    return (
      <ErrorView
        title={'Datadog is not configued'}
        message={`Missing: ${undefinedEnvVariables}`}
        iconVariant={'error'}
        buttons={[]}
      />
    );
  }

  return (
    <DatadogContext.Provider value={{ sessionId: sessionIdMemo }}>
      {children ?? <Outlet />}
    </DatadogContext.Provider>
  );
}
