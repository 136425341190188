import { AvatarBox, AvatarBoxProps, Loading } from '@guider-global/ui';
import { usePicture } from '@guider-global/azure-storage-hooks';

export interface AvatarBoxViewProps extends AvatarBoxProps {
  auth0Picture?: string;
  blobPicture?: string;
  isLoading: boolean;
}

export function AvatarBoxView({
  auth0Picture,
  blobPicture,
  isLoading,
  ...props
}: AvatarBoxViewProps) {
  const { getImage, loading } = usePicture({
    containerName: 'pictures',
  });
  const avatarScr = blobPicture ? getImage(blobPicture) : auth0Picture;

  if (isLoading || loading) return <Loading />;
  return <AvatarBox {...props} avatarSrc={avatarScr} />;
}
