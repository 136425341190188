import {
  AvatarBox,
  Text,
  Button,
  Stack,
  Divider,
  TextStackItem,
  KeyStat,
  KeyStatProps,
} from '@guider-global/ui';
import {
  Grid as MuiGrid,
  Box as MuiBox,
  useTheme,
  useMediaQuery,
} from '@mui/material';
import ArrowBackIcon from '@mui/icons-material/ArrowBack';
import { Outlet } from 'react-router-dom';
import React from 'react';
import { KeyedProps } from '@guider-global/shared-types';

export type SubPageViewProps = {
  title: string;
  subHeading: TextStackItem;
  subtitles: TextStackItem[];
  onBackButtonClick: () => void;
  keyStats?: KeyedProps<KeyStatProps>[];
  avatarSrc?: string;
  headerBackgroundColor?: string;
  bodyBackgroundColor?: string;
  children?: React.ReactElement;
  buttonsElement?: React.ReactElement;
};

export function SubPageView({
  title,
  subHeading,
  subtitles,
  keyStats,
  avatarSrc,
  onBackButtonClick,
  buttonsElement,
  children,
}: SubPageViewProps) {
  const { palette, breakpoints } = useTheme();
  const isMobile = useMediaQuery(breakpoints.down('md'));

  return (
    <Stack direction="column" spacing={0} width="100%">
      <MuiBox
        sx={{
          display: 'flex',
          flexDirection: 'column',
          justifyContent: 'space-between',
          alignItems: 'flex-start',
          background: `linear-gradient(45deg, ${palette.common.blueLight}, ${palette.common.purpleLight})`,
          width: '100%',
          height: '200px',
          px: { xs: 2, md: 6 },
          py: 2,
        }}
      >
        <Button
          label="Back"
          variant="text"
          color="light"
          startIcon={<ArrowBackIcon />}
          onClick={onBackButtonClick}
        />
        {!isMobile && buttonsElement ? (
          <MuiBox
            sx={{
              width: '100%',
              display: 'flex',
              flexDirection: 'row',
              justifyContent: 'flex-end',
              alignItems: 'center',
            }}
          >
            {buttonsElement}
          </MuiBox>
        ) : undefined}
      </MuiBox>
      <MuiGrid
        container
        width="100%"
        sx={{
          backgroundColor: palette.paper.background.primary,
          px: { xs: 2, md: 6 },
        }}
      >
        <MuiGrid item xs={12} md={4} sx={{ mt: -14, py: 2 }}>
          <Text
            variant="h2"
            color={palette.light.main}
            text={title}
            sx={{ mb: 2 }}
          />
          <AvatarBox
            size="large"
            orientation="vertical"
            align={isMobile ? 'center' : 'left'}
            avatarSrc={avatarSrc}
            heading={subHeading}
            subtitles={subtitles}
            textSpacing={1}
          />
        </MuiGrid>
        {isMobile ? (
          <MuiGrid item xs={12}>
            {buttonsElement}
          </MuiGrid>
        ) : undefined}
        {isMobile ? (
          <MuiGrid item xs={12}>
            <Divider />
          </MuiGrid>
        ) : undefined}
        {keyStats ? (
          <Stack direction="row" justifyContent="space-between">
            {keyStats.map((stat) => (
              <KeyStat
                align="center"
                divider={false}
                value={stat.value}
                label={stat.label}
              />
            ))}
          </Stack>
        ) : undefined}
      </MuiGrid>
      <MuiBox
        sx={{
          width: '100%',
          backgroundColor: [palette.default.background.primary],
          py: 3,
          px: { xs: 2, md: 6 },
        }}
      >
        {children ?? <Outlet />}
      </MuiBox>
    </Stack>
  );
}
