import { MembershipProfileStatus } from 'views';

export type GetProfileStatusParams = {
  isPublished: boolean;
  isBlocked: boolean;
  requiresApproval: boolean;
};
export function getProfileStatus({
  isPublished,
  isBlocked,
  requiresApproval,
}: GetProfileStatusParams): MembershipProfileStatus {
  if (isBlocked) return 'blocked';
  if (requiresApproval) return 'requires-approval';
  if (isPublished) return 'listed';
  return 'unlisted';
}
