import { useAuth } from '@guider-global/auth-hooks';
import { getOrigin, getSubDomain } from '@guider-global/front-end-utils';
import { Loading, ErrorView } from '@guider-global/ui';
import { Outlet } from 'react-router-dom';
import { useIntercom } from 'react-use-intercom';

type ProtectedWrapperProps = {
  children: React.ReactNode;
  scope?: string;
};

export function ProtectedWrapper({ children, scope }: ProtectedWrapperProps) {
  const { isAuthenticated, isAuthorized, isLoading, scopesGranted, logout } =
    useAuth({});

  const { show } = useIntercom();

  const organization = getSubDomain();

  if (isLoading) {
    return <Loading withBackdrop={true} isLoading={true} />;
  }

  if (!isAuthenticated || !isAuthorized) {
    logout({ logoutParams: { returnTo: getOrigin() + '/login' } });
    return <>Logging out..</>;
  }

  if (scope && !scopesGranted.includes(scope)) {
    return (
      <ErrorView
        title="You need access"
        message="Ask for access and your request will be reviewed by your organisation admins."
        data={`Missing scope ${scope} for ${organization} organization `}
        iconVariant={'warning'}
        buttons={[
          {
            key: 'request-access',
            label: 'Request Access',
            variant: 'contained',
            onClick: () => show(),
          },
          {
            key: 'log-out',
            label: 'Logout',
            variant: 'text',
            onClick: () =>
              logout({ logoutParams: { returnTo: getOrigin() + '/login' } }),
          },
        ]}
      />
    );
  }

  return <>{children}</> ?? <Outlet />;
}
