import { AvatarBox } from '@guider-global/ui';
import { format } from 'date-fns';

export type RelationshipPartnerStackProps = {
  displayName: string;
  createdAt: Date;
  avatarSrc?: string;
};

export function RelationshipPartnerStack({
  displayName,
  createdAt,
  avatarSrc,
}: RelationshipPartnerStackProps) {
  const formattedDate = createdAt
    ? format(new Date(createdAt), 'dd/MM/yy')
    : 'N/A';
  return (
    <AvatarBox
      heading={displayName}
      size="small"
      subtitles={[`Since: ${formattedDate}`]}
      avatarSrc={avatarSrc}
      sx={{ alignItems: 'center' }}
    />
  );
}
