import { restReduxGenerator } from '@guider-global/redux-axios-hooks';
import {
  IChart,
  IChartsProgramActiveRelationship,
} from '@guider-global/shared-types';

export const adminChartsProgramActiveRelationshipsSlice = restReduxGenerator<
  'adminChartsProgramActiveRelationships',
  IChart<IChartsProgramActiveRelationship>
>('adminChartsProgramActiveRelationships');
