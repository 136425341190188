import { IProfileProgramMembership } from '@guider-global/shared-types';
import { useState } from 'react';
import { RelationshipsTableView, RelationshipsTableViewRowData } from './views';
import { Grid as MuiGrid, Box as MuiBox } from '@mui/material';
import { SuggestedMatchesContainer } from 'containers/SuggestedMatchesContainer';
import { ManualMatchModal } from './views/ManualMatchModal';
import { Button } from '@guider-global/ui';
import PersonAdd from '@mui/icons-material/PersonAdd';
import { MembershipProfileStack, MembershipProfileStatus } from 'views';

export * from './components';

export type ProfileProgramData = {
  programName: string;
  programSlug: string;
  programTypeName: string;
  programTypeSlug: string;
  programVariationTypeSlug: string;
};

export type ProfileProgramMembershipViewProps = {
  userDisplayName: string;
  programName: string;
  programSlug: string;
  membership: IProfileProgramMembership;
  getAvatarSrc: (profileId: string) => string | undefined;
  onNavigateProfile: (profileId: string) => void;
  onUpdateMembershipProfileStatus: (
    membershipId: string,
    membershipProfileStatus: MembershipProfileStatus,
  ) => void;
  onConcludeRelationship: (relationshipId: string) => void;
  createManualMatch: (params: {
    role: 'guide' | 'trainee';
    manualMatchEmail?: string;
    manualMatchProfileId?: string;
  }) => Promise<void>;
  isManualMatchRequestPending: boolean;
};

export function ProfileProgramMembershipView({
  userDisplayName,
  programName,
  programSlug,
  membership,
  getAvatarSrc,
  onNavigateProfile,
  onUpdateMembershipProfileStatus,
  onConcludeRelationship,
  createManualMatch,
  isManualMatchRequestPending,
}: ProfileProgramMembershipViewProps) {
  const {
    role,
    roleLabel,
    otherRoleLabel,
    createdAt,
    skillsLabel,
    skills,
    programFields,
    relationshipProfilesLabel: relationshipNounPluralized,
    relationshipProfiles,
  } = membership;

  // Manual Match Modal
  const [isManualMatchModalOpen, setManualMatchModalOpen] =
    useState<boolean>(false);
  function handleManualMatchOpen() {
    setManualMatchModalOpen(true);
  }
  function handleManualMatchClose() {
    if (isManualMatchRequestPending) return;
    setManualMatchModalOpen(false);
  }
  async function handleCreateManualMatch(params: {
    manualMatchEmail?: string;
    manualMatchProfileId?: string;
  }) {
    await createManualMatch({ role, ...params });
    setManualMatchModalOpen(false);
  }

  const relationshipTableRows =
    relationshipProfiles.map<RelationshipsTableViewRowData>(
      (relationshipProfile) => ({
        id: relationshipProfile.id,
        relationshipId: relationshipProfile.relationshipId,
        avatarSrc: getAvatarSrc(relationshipProfile.id),
        displayName: relationshipProfile.displayName,
        createdAt: relationshipProfile.createdAt,
        status: relationshipProfile.isConcluded ? 'concluded' : 'active',
        sessionCount: relationshipProfile.sessionCount,
      }),
    );

  function onSelectNewStatus(membershipProfileStatus: MembershipProfileStatus) {
    onUpdateMembershipProfileStatus(membership.id, membershipProfileStatus);
  }

  return (
    <MuiGrid container>
      <MuiGrid item sm={5}>
        <MembershipProfileStack
          roleLabel={roleLabel}
          createdAt={createdAt}
          skillsLabel={skillsLabel}
          skills={skills}
          programFields={programFields}
          role={role}
          isPublished={membership.isPublished}
          isBlocked={membership.isBlocked ?? false}
          requiresApproval={membership.requiresApproval ?? false}
          // TODO: Show modal first
          onSelectNewStatus={onSelectNewStatus}
        />
      </MuiGrid>
      <MuiGrid
        item
        xs={7}
        sx={{
          p: 4,
        }}
      >
        <MuiBox
          sx={{
            display: 'flex',
            justifyContent: 'flex-end',
            mb: 1,
          }}
        >
          {role === 'trainee' ? (
            <SuggestedMatchesContainer
              programSlug={programSlug}
              onCreateManualMatch={handleCreateManualMatch}
              isManualMatchRequestPending={isManualMatchRequestPending}
            />
          ) : null}
          <Button
            variant="textWithIcon"
            label={`Add ${otherRoleLabel}`}
            startIcon={<PersonAdd />}
            onClick={handleManualMatchOpen}
          />
        </MuiBox>
        <ManualMatchModal
          show={isManualMatchModalOpen}
          busy={isManualMatchRequestPending}
          userDisplayName={userDisplayName}
          programName={programName}
          otherRoleLabel={otherRoleLabel}
          onClose={handleManualMatchClose}
          onCreateManualMatch={handleCreateManualMatch}
        />
        <RelationshipsTableView
          nounPluralized={relationshipNounPluralized}
          rows={relationshipTableRows}
          onNavigateProfile={onNavigateProfile}
          onConclude={onConcludeRelationship}
        />
      </MuiGrid>
    </MuiGrid>
  );
}
