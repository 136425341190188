import { restReduxGenerator } from '@guider-global/redux-axios-hooks';
import {
  IChart,
  IChartsOverviewGuideProfile,
} from '@guider-global/shared-types';

export const adminChartsOverviewGuideProfilesSlice = restReduxGenerator<
  'adminChartsOverviewGuideProfiles',
  IChart<IChartsOverviewGuideProfile>
>('adminChartsOverviewGuideProfiles');
