import { storageTokenSlice } from '@guider-global/azure-storage-hooks';
import { IRestState, PickByType } from '@guider-global/redux-axios-hooks';
import {
  sanityBaseLanguageSlice,
  sanityLocalizationSlice,
  sanityOrganizationProgramsSlice,
  sanityOrganizationSlice,
  sanityProgramSlice,
} from '@guider-global/sanity-hooks';
import {
  IChart,
  IChartsRelationships,
  IChartsUser,
  IEvent,
  IMatch,
  IMetric,
  IMetricToken,
  IOrganization,
  IProfile,
  IProgramDetails,
  IProgramProfile,
  IRelationship,
  ISetting,
  IUser,
  IUserProfile,
  OrganizationIntegration,
} from '@guider-global/shared-types';
import { Middleware, configureStore } from '@reduxjs/toolkit';
import { TypedUseSelectorHook, useDispatch, useSelector } from 'react-redux';
import {
  adminChartsGoalsSlice,
  adminChartsOverviewGuideProfilesSlice,
  adminChartsOverviewTraineeProfilesSlice,
  adminChartsProgramActiveRelationshipsSlice,
  adminChartsProgramConcludedRelationshipsSlice,
  adminChartsProgramGoalsSlice,
  adminChartsProgramGuideProfilesSlice,
  adminChartsProgramTraineeProfilesSlice,
  adminChartsRelationshipsSlice,
  adminChartsSessionsSlice,
  adminChartsSignupsSlice,
  adminChartsSkillsOfferedSlice,
  adminChartsSkillsSoughtSlice,
  adminChartsUsersSlice,
  adminKPIsOrganizationSlice,
  adminKPIsProgramSlice,
  adminManualMatchSlice,
  adminMatchesSlice,
  adminMembershipsSlice,
  adminProfileProgramOverviewsSlice,
  adminProgramsSlice,
  adminRelationshipsSlice,
  adminUserProfilesSlice,
  eventsSlice,
  metricsSlice,
  metricsTokensSlice,
  organizationIntegrationsSlice,
  organizationsSlice,
  profilesSlice,
  settingsSlice,
  testSlice,
  usersSlice,
} from './slices';
import { adminProgramProfilesSlice } from './slices/adminProgramProfilesSlice';

const rootReducer = {
  sanityOrganization: sanityOrganizationSlice.reducer,
  sanityProgram: sanityProgramSlice.reducer,
  adminMatches: adminMatchesSlice.reducer,
  adminMemberships: adminMembershipsSlice.reducer,
  adminChartsGoals: adminChartsGoalsSlice.reducer,
  adminChartsSessions: adminChartsSessionsSlice.reducer,
  adminChartsSkillsSought: adminChartsSkillsSoughtSlice.reducer,
  adminChartsSkillsOffered: adminChartsSkillsOfferedSlice.reducer,
  adminChartsUsers: adminChartsUsersSlice.reducer,
  adminChartsRelationships: adminChartsRelationshipsSlice.reducer,
  adminChartsSignups: adminChartsSignupsSlice.reducer,
  adminChartsProgramActiveRelationships:
    adminChartsProgramActiveRelationshipsSlice.reducer,
  adminChartsProgramConcludedRelationships:
    adminChartsProgramConcludedRelationshipsSlice.reducer,
  adminChartsProgramGuideProfiles: adminChartsProgramGuideProfilesSlice.reducer,
  adminChartsProgramTraineeProfiles:
    adminChartsProgramTraineeProfilesSlice.reducer,
  adminChartsProgramGoals: adminChartsProgramGoalsSlice.reducer,
  adminChartsOverviewTraineeProfiles:
    adminChartsOverviewTraineeProfilesSlice.reducer,
  adminChartsOverviewGuideProfiles:
    adminChartsOverviewGuideProfilesSlice.reducer,
  adminKPIsOrganization: adminKPIsOrganizationSlice.reducer,
  adminKPIsProgram: adminKPIsProgramSlice.reducer,
  adminManualMatch: adminManualMatchSlice.reducer,
  adminRelationships: adminRelationshipsSlice.reducer,
  adminProfileProgramOverviews: adminProfileProgramOverviewsSlice.reducer,
  adminUserProfiles: adminUserProfilesSlice.reducer,
  adminProgramProfiles: adminProgramProfilesSlice.reducer,
  adminPrograms: adminProgramsSlice.reducer,
  users: usersSlice.reducer,
  test: testSlice.reducer,
  organizations: organizationsSlice.reducer,
  metrics: metricsSlice.reducer,
  metricsTokens: metricsTokensSlice.reducer,
  settings: settingsSlice.reducer,
  profiles: profilesSlice.reducer,
  sanityLocalization: sanityLocalizationSlice.reducer,
  sanityBaseLanguage: sanityBaseLanguageSlice.reducer,
  sanityOrganizationPrograms: sanityOrganizationProgramsSlice.reducer,
  events: eventsSlice.reducer,
  storageToken: storageTokenSlice.reducer,
  organizationIntegrations: organizationIntegrationsSlice.reducer,
};

const middleware: Middleware[] = [];

export const store = configureStore({
  reducer: rootReducer,
  middleware: middleware,
});

/** Core Redux Types */
export type StoreStateGetter = typeof store.getState;
export type RootState = ReturnType<StoreStateGetter>;
export type AppDispatch = typeof store.dispatch;
export type DispatchFunc = () => AppDispatch;

/** Typed dispatches & selectors */
export const useAppDispatch: DispatchFunc = useDispatch;
export const useAppSelector: TypedUseSelectorHook<RootState> = useSelector;

/** The redux state that defines which reducers are generated Rest reducers */
export type RestRootState = Pick<
  ReturnType<StoreStateGetter>,
  | 'organizations'
  | 'users'
  | 'adminUserProfiles'
  | 'adminProfileProgramOverviews'
  | 'adminMatches'
  | 'adminMemberships'
  | 'adminChartsGoals'
  | 'adminChartsSkillsSought'
  | 'adminChartsSkillsOffered'
  | 'adminChartsSessions'
  | 'adminChartsUsers'
  | 'adminChartsRelationships'
  | 'adminChartsProgramActiveRelationships'
  | 'adminChartsProgramConcludedRelationships'
  | 'adminChartsProgramGuideProfiles'
  | 'adminChartsProgramTraineeProfiles'
  | 'adminChartsProgramGoals'
  | 'adminKPIsOrganization'
  | 'adminKPIsProgram'
  | 'adminManualMatch'
  | 'adminRelationships'
  | 'adminProgramProfiles'
  | 'adminPrograms'
  | 'metrics'
  | 'metricsTokens'
  | 'settings'
  | 'profiles'
  | 'events'
  | 'organizationIntegrations'
  | 'adminChartsOverviewTraineeProfiles'
  | 'adminChartsOverviewGuideProfiles'
  | 'adminChartsSignups'
>;
/** The types of generated Rest Reducers */
export type RestRootStateTypes =
  | IOrganization
  | IUser
  | IUserProfile
  | IProgramProfile
  | IProgramDetails
  | IMatch
  | IChart<IChartsUser>
  | IChart<IChartsRelationships>
  | IRelationship
  | IMetric
  | IMetricToken
  | ISetting
  | IProfile
  | IEvent
  | OrganizationIntegration;
/** A selector for generated Rest Reducers */
export const useRestAppSelector: TypedUseSelectorHook<
  // eslint-disable-next-line @typescript-eslint/no-explicit-any
  PickByType<RootState, IRestState<any>>
> = useSelector;

export default store;
