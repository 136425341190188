import {
  IProfileProgramCustomField,
  IProfileProgramSkill,
} from '@guider-global/shared-types';
import { TextStack } from '@guider-global/ui';
import { Box as MuiBox, Grid as MuiGrid, useTheme } from '@mui/material';
import { format } from 'date-fns';
import { MembershipProfileStatus, MembershipStatusStack } from 'views';

export * from './MembershipStatusStack';

export type MembershipProfileStackProps = {
  roleLabel: string;
  createdAt: Date;
  skillsLabel: string;
  skills: IProfileProgramSkill[];
  programFields: IProfileProgramCustomField[];
  role: 'guide' | 'trainee';
  isPublished: boolean;
  isBlocked: boolean;
  requiresApproval: boolean;
  onSelectNewStatus: (
    membershipProfileStatus: MembershipProfileStatus,
  ) => void | Promise<void>;
};

export function MembershipProfileStack({
  roleLabel,
  createdAt,
  skillsLabel,
  skills,
  programFields,
  role,
  isPublished,
  isBlocked,
  requiresApproval,
  onSelectNewStatus,
}: MembershipProfileStackProps) {
  const { palette } = useTheme();
  const formattedDate = createdAt
    ? format(new Date(createdAt), 'dd/MM/yy')
    : 'N/A';
  const profileCreatedLabel = `Profile Created: ${formattedDate}`;
  return (
    <MuiBox sx={{ px: 4, py: 4 }}>
      <MuiBox sx={{ pb: 2 }}>
        <MuiGrid container>
          <MuiGrid item sm={6}>
            <TextStack
              size={'medium'}
              heading={{
                text: `${roleLabel} Profile`,
                variant: 'h3',
                component: 'p',
                sx: {
                  color: palette.primary.main,
                },
              }}
              subtitles={[profileCreatedLabel]}
            />
          </MuiGrid>
          <MuiGrid item sm={3}>
            <MembershipStatusStack
              role={role}
              isPublished={isPublished}
              isBlocked={isBlocked}
              requiresApproval={requiresApproval}
              onSelectNewStatus={onSelectNewStatus}
            />
          </MuiGrid>
        </MuiGrid>
      </MuiBox>
      <MuiBox sx={{ py: 2 }}>
        <TextStack
          size={'medium'}
          heading={skillsLabel}
          subtitles={[skills.map((skill) => skill.label).join(', ')]}
        />
      </MuiBox>
      {programFields.map((programField) => (
        <MuiBox key={programField.id} sx={{ py: 2 }}>
          <TextStack
            size={'medium'}
            heading={programField.label}
            subtitles={[
              (() => {
                const { value } = programField;
                if (Array.isArray(value)) return value.join(', ');
                return `${value}`;
              })(),
            ]}
          />
        </MuiBox>
      ))}
    </MuiBox>
  );
}
