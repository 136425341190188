import { restReduxGenerator } from '@guider-global/redux-axios-hooks';
import {
  IChart,
  IChartsOverviewTraineeProfile,
} from '@guider-global/shared-types';

export const adminChartsOverviewTraineeProfilesSlice = restReduxGenerator<
  'adminChartsOverviewTraineeProfiles',
  IChart<IChartsOverviewTraineeProfile>
>('adminChartsOverviewTraineeProfiles');
