import { Chip, Table, Text } from '@guider-global/ui';
import { GridColDef, GridRenderCellParams } from '@mui/x-data-grid';
import {
  RelationshipPartnerStack,
  RelationshipConcludeModal,
  RelationshipManageDropDown,
} from './components';
import { ButtonBase as MuiButtonBase, Box as MuiBox } from '@mui/material';
import { useState } from 'react';

export * from './components';

export type RelationshipsTableViewRowData = {
  id: string;
  relationshipId: string;
  avatarSrc?: string;
  displayName: string;
  createdAt: Date;
  status: 'active' | 'concluded';
  sessionCount: number;
};

export type RelationshipsTableViewProps = {
  nounPluralized: string;
  rows: RelationshipsTableViewRowData[];
  onNavigateProfile: (profileId: string) => void;
  onConclude: (relationshipId: string) => void;
};

export function RelationshipsTableView({
  nounPluralized,
  rows,
  onNavigateProfile,
  onConclude,
}: RelationshipsTableViewProps) {
  const [promptToConcludeId, setPromptToConcludeId] = useState<
    string | undefined
  >(undefined);

  function renderPartnerCell(
    params: GridRenderCellParams<
      RelationshipsTableViewRowData,
      Pick<
        RelationshipsTableViewRowData,
        'id' | 'displayName' | 'createdAt' | 'avatarSrc'
      >
    >,
  ) {
    if (!params.value) return <></>;
    const {
      value: { id: profileId, displayName, createdAt, avatarSrc },
    } = params;
    return (
      <MuiButtonBase onClick={() => onNavigateProfile(profileId)}>
        <RelationshipPartnerStack
          displayName={displayName}
          createdAt={createdAt}
          avatarSrc={avatarSrc}
        />
      </MuiButtonBase>
    );
  }

  function renderRelationshipStatusCell(
    params: GridRenderCellParams<
      RelationshipsTableViewRowData,
      'active' | 'concluded'
    >,
  ) {
    const { value: status } = params;
    if (status === 'active') {
      return <Chip label="Active" color="secondary" />;
    }
    if (status === 'concluded') {
      return <Chip label="Concluded" color="primary" />;
    }
    return 'N/A';
  }

  function renderManageCell(
    params: GridRenderCellParams<
      RelationshipsTableViewRowData,
      Pick<RelationshipsTableViewRowData, 'relationshipId' | 'status'>
    >,
  ) {
    if (!params.value) return <></>;
    const {
      value: { relationshipId, status },
    } = params;
    return (
      <RelationshipManageDropDown
        status={status}
        onSelectConclude={() => {
          setPromptToConcludeId(relationshipId);
        }}
      />
    );
  }

  const tableColumns: GridColDef[] = [
    { field: 'id', headerName: 'ID' },
    {
      field: 'partner',
      headerName: nounPluralized,
      renderCell: renderPartnerCell,
      flex: 2,
    },
    {
      field: 'status',
      headerName: 'Relationship Status',
      renderCell: renderRelationshipStatusCell,
      flex: 1,
    },
    { field: 'sessionCount', headerName: 'Sessions Held', flex: 1 },
    {
      field: 'manage',
      headerName: 'Manage',
      renderCell: renderManageCell,
      flex: 1,
    },
  ];

  const tableRows = rows.map(
    ({
      id,
      displayName,
      createdAt,
      avatarSrc,
      status,
      sessionCount,
      relationshipId,
    }) => ({
      id: id,
      partner: { id, displayName, createdAt, avatarSrc },
      status: status,
      sessionCount: sessionCount,
      manage: { status, relationshipId },
    }),
  );

  function onConcludeModalConfirm() {
    if (!promptToConcludeId) return;
    onConclude(promptToConcludeId);
    setPromptToConcludeId(undefined);
  }

  function onConcludeModalCancel() {
    setPromptToConcludeId(undefined);
  }

  const hasRelationships = rows.length > 0;
  if (!hasRelationships) {
    return (
      <MuiBox
        sx={{
          mt: 2,
          px: 3,
          py: 3,
          textAlign: 'center',
          boxShadow: '0px 0px 0px 1px #E0E0E0',
          borderRadius: '8px',
        }}
      >
        <Text text={`No Relationships Yet`} variant="h3" />
      </MuiBox>
    );
  }

  return (
    <>
      <MuiBox>
        <Table
          rows={tableRows}
          columns={tableColumns}
          page={0}
          pageSize={25}
          pageSizeOptions={[5, 10, 25]}
          columnVisibilityModel={{
            id: false,
          }}
          disableColumnSelector
          disableColumnMenu
          disableRowSelectionOnClick
          hideFooter
        />
      </MuiBox>
      <RelationshipConcludeModal
        isOpen={promptToConcludeId !== undefined}
        onConfirm={onConcludeModalConfirm}
        onCancel={onConcludeModalCancel}
      />
    </>
  );
}
