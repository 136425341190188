import { FormModal, TextField } from '@guider-global/ui';
import { useState } from 'react';

export type ManualMatchModalProps = {
  show: boolean;
  busy: boolean;
  userDisplayName: string;
  programName: string;
  otherRoleLabel: string;
  onCreateManualMatch: (params: { manualMatchEmail: string }) => void;
  onClose: () => void;
};

export function ManualMatchModal({
  show,
  busy,
  userDisplayName,
  programName,
  otherRoleLabel,
  onCreateManualMatch,
  onClose,
}: ManualMatchModalProps) {
  const [manualMatchEmail, setManualMatchEmail] = useState<string>();

  function getFormValid() {
    if (typeof manualMatchEmail !== 'string') return false;
    if (manualMatchEmail.length === 0) return false;
    return true;
  }
  const isFormValid = getFormValid();

  return (
    <FormModal
      heading={`Add ${otherRoleLabel}`}
      descriptions={[
        `Add ${otherRoleLabel.toLowerCase()} for ${userDisplayName} in the program: ${programName}`,
      ]}
      open={show}
      primaryButtonProps={{
        label: `Add ${otherRoleLabel}`,
        variant: 'contained',
        onClick: () =>
          onCreateManualMatch({ manualMatchEmail: manualMatchEmail as string }),
        disabled: !isFormValid || busy,
        loading: busy,
      }}
      secondaryButtonProps={{
        label: 'Cancel',
        variant: 'outlined',
        onClick: onClose,
        disabled: busy,
      }}
      handleClose={onClose}
      isLoading={false}
    >
      <TextField
        type="email"
        placeholder="e.g. janedoe@workemail.com"
        label={`${otherRoleLabel} Email Address`}
        onChange={(e) => setManualMatchEmail(e.target.value)}
      />
    </FormModal>
  );
}
