import {
  DropDown,
  IconButton,
  PersonOffIcon,
  AvatarBox,
} from '@guider-global/ui';
import MoreHorizIcon from '@mui/icons-material/MoreHoriz';
import { MenuItem as MuiMenuItem } from '@mui/material';

export type RelationshipManageDropDownProps = {
  status: 'active' | 'concluded';
  onSelectConclude: () => void;
};

export function RelationshipManageDropDown({
  status,
  onSelectConclude,
}: RelationshipManageDropDownProps) {
  const optionsEnabled = {
    conclude: status === 'active',
  };
  const enabledOptionCount = Object.values(optionsEnabled).reduce(
    (sum, isOptionEnabled) => sum + (isOptionEnabled ? 1 : 0),
    0,
  );
  const isDropDownEnabled = enabledOptionCount > 0;

  return (
    <DropDown
      anchor={
        <IconButton
          variant="outlined"
          disabled={!isDropDownEnabled}
          icon={<MoreHorizIcon />}
        />
      }
      header="Manage relationship"
    >
      <MuiMenuItem
        onClick={onSelectConclude}
        disabled={!optionsEnabled.conclude}
      >
        <AvatarBox
          icon={<PersonOffIcon size="small" />}
          size="small"
          heading="Conclude Relationship"
          subtitles={[
            'This will conclude the relationship meaning that both users wil no longer be able to message or schedule a session.',
          ]}
        />
      </MuiMenuItem>
    </DropDown>
  );
}
