import { restReduxGenerator } from '@guider-global/redux-axios-hooks';
import {
  IChart,
  IChartsProgramConcludedRelationship,
} from '@guider-global/shared-types';

export const adminChartsProgramConcludedRelationshipsSlice = restReduxGenerator<
  'adminChartsProgramConcludedRelationships',
  IChart<IChartsProgramConcludedRelationship>
>('adminChartsProgramConcludedRelationships');
