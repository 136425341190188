import { Divider, Card, Text } from '@guider-global/ui';
import { Box as MuiBox, useTheme } from '@mui/material';
import { IProfileProgramOverview } from '@guider-global/shared-types';
import { intersperseMap } from '@guider-global/front-end-utils';
import { MembershipProfileStatus } from './types';
import { ProfileProgramMembershipView } from 'views';
import { CreateManualMatchParams } from 'containers/ProfileProgramOverviewListContainer/hooks/useManualMatchController';

export * from './views';
export * from './types';

export type ProfileProgramViewProps = {
  userEmail: string;
  userProfileId: string;
  userDisplayName: string;
  profileProgramOverview: IProfileProgramOverview;
  getAvatarSrc: (profileId: string) => string | undefined;
  onNavigateProfile: (profileId: string) => void;
  onUpdateMembershipProfileStatus: (
    membershipId: string,
    membershipProfileStatus: MembershipProfileStatus,
  ) => void;
  onConcludeRelationship: (relationshipId: string) => void;
  createManualMatch: (params: CreateManualMatchParams) => Promise<void>;
  isManualMatchRequestPending: boolean;
};

export function ProfileProgramView({
  userEmail,
  userProfileId,
  userDisplayName,
  profileProgramOverview,
  getAvatarSrc,
  onNavigateProfile,
  onUpdateMembershipProfileStatus,
  onConcludeRelationship,
  createManualMatch,
  isManualMatchRequestPending,
}: ProfileProgramViewProps) {
  const { palette } = useTheme();
  const { memberships } = profileProgramOverview;
  const sortedMemberships = [...memberships].sort((a, b) => {
    if (a.role === b.role) return a.id.localeCompare(b.id, 'en');
    if (a.role === 'guide') return -1;
    if (a.role === 'trainee') return 1;
    return 0;
  });

  const {
    programSlug,
    programName,
    programTypeSlug,
    programVariationTypeSlug,
  } = profileProgramOverview;

  async function handleCreateManualMatch(params: {
    role: 'guide' | 'trainee';
    manualMatchEmail?: string;
    manualMatchProfileId?: string;
  }) {
    const { role, manualMatchEmail, manualMatchProfileId } = params;
    return createManualMatch({
      role,
      userEmail,
      userProfileId,
      manualMatchEmail,
      manualMatchProfileId,
      programSlug,
      programTypeSlug,
      programVariationTypeSlug,
    });
  }

  return (
    <Card sx={{ height: 'auto', my: 2 }}>
      <>
        <MuiBox
          sx={{
            width: '100%',
            height: '4em',
            padding: '16px 24px',
            backgroundColor: palette.default.hover.light,
            boxShadow: '0px 0px 0px 1px #E0E0E0',
            borderRadius: '8px 8px 0px 0px',
          }}
        >
          <Text text={profileProgramOverview.programName} variant="h3" />
        </MuiBox>
        {intersperseMap(
          sortedMemberships,
          (membership) => (
            <ProfileProgramMembershipView
              key={membership.id}
              userDisplayName={userDisplayName}
              programName={programName}
              programSlug={programSlug}
              membership={membership}
              getAvatarSrc={getAvatarSrc}
              onNavigateProfile={onNavigateProfile}
              onUpdateMembershipProfileStatus={onUpdateMembershipProfileStatus}
              onConcludeRelationship={onConcludeRelationship}
              createManualMatch={handleCreateManualMatch}
              isManualMatchRequestPending={isManualMatchRequestPending}
            />
          ),
          (membership) => (
            <MuiBox
              key={`${membership.id}-intersperse`}
              display="flex"
              flexDirection="row"
              justifyContent="center"
              sx={{ py: 3 }}
            >
              <Divider width="90%" />
            </MuiBox>
          ),
        )}
      </>
    </Card>
  );
}
