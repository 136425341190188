import { MembershipProfileState } from 'views';
import { getProfileStatus } from '../getProfileStatus';

export type GetMembershipProfileStateParams = {
  role: 'guide' | 'trainee';
  isPublished: boolean;
  isBlocked: boolean;
  requiresApproval: boolean;
};
export function getMembershipProfileState({
  role,
  isPublished,
  isBlocked,
  requiresApproval,
}: GetMembershipProfileStateParams): MembershipProfileState {
  if (role === 'guide') {
    const profileStatus = getProfileStatus({
      isPublished,
      isBlocked,
      requiresApproval,
    });
    return {
      isGuide: true,
      profileStatus,
    };
  }
  return { isGuide: false };
}
