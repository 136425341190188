import { restReduxGenerator } from '@guider-global/redux-axios-hooks';
import {
  IChart,
  IChartsProgramTraineeProfile,
} from '@guider-global/shared-types';

export const adminChartsProgramTraineeProfilesSlice = restReduxGenerator<
  'adminChartsProgramTraineeProfiles',
  IChart<IChartsProgramTraineeProfile>
>('adminChartsProgramTraineeProfiles');
