import { Stack, TextStack } from '@guider-global/ui';
import { Box as MuiBox, useTheme, SxProps, Theme } from '@mui/material';
import { Outlet } from 'react-router-dom';
import React from 'react';

export type PageViewProps = {
  title: string;
  subtitles: string[];
  headerElement?: React.ReactNode;
  children?: JSX.Element | JSX.Element[];
  sx?: SxProps<Theme>;
  headerSx?: SxProps<Theme>;
};

export function PageView({
  title,
  subtitles,
  headerElement,
  children,
  sx,
  headerSx,
}: PageViewProps) {
  const { palette } = useTheme();

  return (
    <Stack direction="column" width="100%" spacing={0}>
      <MuiBox
        sx={{
          display: 'flex',
          flexDirection: 'row',
          justifyContent: 'space-between',
          alignItems: 'center',
          width: '100%',
          py: 3,
          px: { xs: 3, md: 6 },
          backgroundColor: palette.paper.background.primary,
          ...headerSx,
        }}
      >
        <TextStack
          heading={{ text: title, variant: 'h2', component: 'h2' }}
          subtitles={subtitles}
          size="large"
          sx={{ mr: headerElement ? 2 : 0 }}
        />
        {headerElement}
      </MuiBox>

      <MuiBox
        sx={{
          display: 'flex',
          flexDirection: 'column',
          width: '100%',
          py: 3,
          px: { xs: 3, md: 6 },
          backgroundColor: palette.default.background.primary,
          ...sx,
        }}
      >
        {children ?? <Outlet />}
      </MuiBox>
    </Stack>
  );
}
